jQuery(document).ready(function ($) {

	// Check if reference container is present
	var filterContainer = document.getElementsByClassName("filter-container");
	if (filterContainer.length > 0) {
		// Get parameter from URL
		var queryParams = new URLSearchParams(window.location.search);
		var param = queryParams.get("category");

		// If URL has no parameter, set parameter to 'all'
		if (param == null) {
			param = "all";
		}

		let mobileSel = $("#mobile-selected-cat");

		// Expand category content (info about the category itself shown above all items)
		$(".category-content." + param).addClass("expanded");

		// Options for Filterizr
		var filterizrOptions = {
			animationDuration: 0.25,
			easing: "ease-out",
			layout: "sameSize",
			gutterPixels: 20,
			delay: 1,
			filterOutCss: {
				pointerEvents: "none", // Fixes strange bug
			},
			filterInCss: {
				pointerEvents: "all", // Fixes fix for strange bug!
			},
		};
		// Create filterizr
		var filterizr = new Filterizr(".filter-container", filterizrOptions);
		// Init filterizr and filter on parameter from URL
		filterizr;
		filterizr.toggleFilter(param);

		// Click on filter button
		var buttonFilter = $("button[data-filter]");
		$('button[data-filter="' + param + '"]').addClass("active");

		buttonFilter.click(function () {
			// Remove active class from all filter buttons
			buttonFilter.removeClass("active");

			// Add active class to clicked button
			$(this).addClass("active");

			// Get category from buttons data-filter
			var cat = $(this).attr("data-filter");

			console.log(cat);

			mobileSel.text($(this).text());
			mobileSel.removeClass("show-cats");

			// Update URL parameter with the new value
			queryParams.set("category", cat);
			history.replaceState(null, null, "?category=" + cat);

			// Remove expanded class from all category content wrappers
			$(".category-content").removeClass("expanded");

			// Add expanded class to the active category content
			$(".category-content." + cat).addClass("expanded");
		}); // buttonFilter.click()
	} // if( filterContainer.length > 0 )

	let activeButton = $(".btn.active").text();

	let mobileSel = $("#mobile-selected-cat");

	mobileSel.text(activeButton);

	mobileSel.click(function () {
		$(this).toggleClass("show-cats");
	});

	/**
	 * Main nav hamburger
	 */

	document.getElementById("hamburger").addEventListener("click", function () {
		console.log(":(");
		console.log("Klasser på body:", document.body.className);
		document.body.classList.toggle("expanded-nav");
		console.log("Klasser på body:", document.body.className);
	});

	/**
	 * Gallery
	 */
	const overlayImage = document.querySelector(".gallery-overlay__image img");
	function galleryImageClick(event) {
		document.body.classList.add("show-gallery");
		const image = event.target;
		image.classList.add("active");
		const galleryImage = image.getAttribute("data-gallery-image");
		const galleryCaption = image.getAttribute("data-gallery-caption");

		// Update the image's src, height and width
		overlayImage.src = galleryImage;
		overlayImage.width = image.width;
		overlayImage.height = image.height;

		// Update the caption
		const overlayCaption = document.querySelector(".gallery-overlay__caption");
		overlayCaption.textContent = galleryCaption;
	}

	// Add click event for all images
	let galleryImageArray = [];
	const galleryImages = document.querySelectorAll(".gallery-image");
	galleryImages.forEach((image) => {
		galleryImageArray.push(
			image.querySelector("img").getAttribute("data-gallery-image")
		);
		image.addEventListener("click", galleryImageClick);
	});

	// Close gallery overlay when clicking outside
	const galleryOverlay = document.querySelector(".gallery-overlay");
	if (galleryOverlay) {
		galleryOverlay.addEventListener("click", function () {
			document.body.classList.remove("show-gallery");
		});
	}

	// Stop gallery overlay from closing when clicking on caption
	const overlayCaption = document.querySelector(".gallery-overlay__caption");
	if (overlayCaption) {
		overlayCaption.addEventListener("click", function (event) {
			event.stopPropagation();
		});
	}

	// Goto next gallery image
	function nextGalleryImage() {
		let index = galleryImageArray.indexOf(overlayImage.src);
		if (index > 0) {
			document
				.querySelector(`img[src="${galleryImageArray[index]}"]`)
				.setAttribute("src", galleryImageArray[index - 1]);
		} else {
			document
				.querySelector(`img[src="${galleryImageArray[index]}"]`)
				.setAttribute("src", galleryImageArray[galleryImageArray.length - 1]);
		}
	}

	// Goto next gallery image
	function previousGalleryImage() {
		let index = galleryImageArray.indexOf(overlayImage.src);
		if (index >= galleryImageArray.length - 1) {
			document
				.querySelector(`img[src="${galleryImageArray[index]}"]`)
				.setAttribute("src", galleryImageArray[0]);
		} else {
			document
				.querySelector(`img[src="${galleryImageArray[index]}"]`)
				.setAttribute("src", galleryImageArray[index + 1]);
		}
	}

	// Click on previous image button
	const galleryPrevElement = document.querySelector(".gallery-prev");
	if (galleryPrevElement) {
		galleryPrevElement.addEventListener("click", function (event) {
			nextGalleryImage();
			event.stopPropagation();
		});
	}

	// Previous gallery image on arrow right
	document.addEventListener("keydown", function (event) {
		if (event.key === "ArrowLeft") {
			previousGalleryImage();
		}
	});

	// Click on next image button
	const galleryNextElement = document.querySelector(".gallery-next");
	if (galleryNextElement) {
		galleryNextElement.addEventListener("click", function (event) {
			previousGalleryImage();
			event.stopPropagation();
		});
	}

	// Next gallery image on arrow right
	document.addEventListener("keydown", function (event) {
		if (event.key === "ArrowRight") {
			nextGalleryImage();
		}
	});

	// Close gallery on escape
	document.addEventListener("keydown", function (event) {
		if (event.key === "Escape" || event.key === "Esc") {
			if (document.body.classList.contains("show-gallery")) {
				document.body.classList.remove("show-gallery");
				console.log("show-gallery-klassen togs bort från body");
			}
		}
	});
}); // jQuery(document)